<template>
  <CheckoutLayout :step="1">
    <div class="row">
      <template v-if="itemsInCart > 0">
        <div class="col-12">
          <p class="checkout__card-title">
            Liczba zdjęć w koszyku: {{ itemsInCart }}
          </p>
        </div>

        <div class="col-12 order-2 order-xxl-1 col-xxl-9">
          <CartList
            :show-titles="true"
            :show-column-headers="true"
            layout="wide"
          />
        </div>

        <div class="order-1 order-xxl-2 col-12 col-xxl-3">
          <div class="checkout__payment-box">
            <div class="payment-box">
              <p class="payment-box__header">
                Zamów zdjęcia
                <span class="payment-box__count"
                  >(Liczba zdjęć: {{ itemsInCart }})</span
                >
              </p>

              <div class="payment-box__content">
                <p class="payment-box__text">
                  Kwota do zapłaty:
                  <span class="payment-box__price">{{ cartTotal }}</span>
                </p>
              </div>

              <p class="payment-box__next">
                <router-link
                  :to="{ name: nextStepRoute }"
                  class="button button--large button--full button--alt"
                >
                  Przejdź do płatności
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </template>

      <div v-if="itemsInCart === 0" class="col-12">
        <div class="checkout__empty">
          <div class="info-box info-box--icon info-box--card">
            <p class="info-box__text">
              Twój koszyk jest pusty.
            </p>

            <div class="info-box__button">
              <router-link
                :to="{ name: 'categories-page' }"
                class="button button--strong"
              >
                Zobacz polecane kategorie
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="checkout__categories">
          <FeaturedProducts />
        </div>
      </div>
    </div>
  </CheckoutLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import CheckoutLayout from '../checkout-layout/checkout-layout.vue';
import CartList from '../cart-list/cart-list.vue';
import FeaturedProducts from '../featured-products/featured-products.vue';
import { getCart } from '../../moltin/helpers';
import isLoggedIn from '@/vue/utils/isLoggedIn';

export default {
  name: 'CheckoutCart',
  components: {
    CartList,
    FeaturedProducts,
    CheckoutLayout,
  },
  metaInfo: {
    title: 'Koszyk',
  },
  asyncComputed: {
    isLoggedIn() {
      return isLoggedIn();
    },
    cartTotal: {
      async get() {
        const cart = await getCart();
        return cart.meta.display_price.with_tax.formatted;
      },
      watch: ['productsIds'],
    },
    nextStepRoute() {
      return this.isLoggedIn ? 'checkout-payment' : 'checkout-auth';
    },
  },
  computed: {
    ...mapGetters('cart', {
      loadingCart: 'loading',
      itemsInCart: 'count',
      productsIds: 'productsIds',
    }),
  },
};
</script>
