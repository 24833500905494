<template>
  <div class="checkout">
    <div class="checkout__breadcrumbs">
      <div class="container">
        <ul class="breadcrumbs">
          <li
            :class="{
              'breadcrumbs__item--active': step === 1,
            }"
            class="breadcrumbs__item breadcrumbs__item--1"
          >
            Twój koszyk
          </li>
          <li
            :class="{
              'breadcrumbs__item--active': step === 2,
            }"
            class="breadcrumbs__item breadcrumbs__item--2"
          >
            Płatność
          </li>
          <li
            :class="{
              'breadcrumbs__item--active': step === 3,
            }"
            class="breadcrumbs__item breadcrumbs__item--3"
          >
            Podsumowanie zamówienia
          </li>
        </ul>
      </div>
    </div>

    <div class="container">
      <div class="checkout__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutLayout',
  props: {
    step: {
      required: true,
      type: Number,
      validator(number) {
        return [1, 2, 3].includes(number);
      },
    },
  },
};
</script>
